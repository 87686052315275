<template>
    <transition name="fade">
      <div class="film" v-if="loaded">
         <button @click="onButtonfilmCloseClicked" type="button" name="button" class="film-close" >
             <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter x="-27.4%" y="-20.8%" width="154.9%" height="154.9%" filterUnits="objectBoundingBox" id="a"><feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/><feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/><feMorphology radius="1" in="SourceAlpha" result="shadowInner"/><feOffset dy="2" in="shadowInner" result="shadowInner"/><feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/></filter><path d="M.185.185l29.63 29.63m0-29.63L.185 29.815" id="b"/></defs><g transform="translate(5 3)" fill="none" fill-rule="evenodd" stroke-linecap="square"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use stroke="#FFF" stroke-width="2" xlink:href="#b"/></g></svg>
         </button>
        <!-- <div class="vimeo no-gutter">
            <div class="embed-responsive" :class="'embed-responsive-' + film.aspectration.value">
              <iframe class="embed-responsive-item" :src="'https://player.vimeo.com/video/' + film.vimeoId + '?autoplay=1&loop=1&autopause=0'" @load="onIframeLoaded" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        </div> -->
        <template v-for="subfilm in film.subFilms" :key="subfilm.vimeoId">
            <div class="vimeo no-gutter">
                <div class="embed-responsive" :class="'embed-responsive-' + subfilm.aspectration">
                  <iframe class="embed-responsive-item" :src="'https://player.vimeo.com/video/' + subfilm.vimeoId + '?autoplay='+ subfilm.autoplay +'&loop=1&autopause=0'" @load="onIframeLoaded" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </template>
        <div class="description">
            <div class="left">
                <!-- <p>{{film.title}}</p> -->
                <div v-html="film.description" class="small"></div>
            </div>
            <div class="right">

                <div v-html="film.abstract" v-if="film.abstract !== '<p></p>'"></div>
                <!-- <p>{{film.author}}</p><br> -->
                <router-link :to="{ name: 'Home', params: {} }"><p>All Films</p></router-link>
            </div>
        </div>
      </div>
  </transition>
</template>

<script>
export default {
  name: 'Film',
  props: {
      slug: null
  },
  data () {
      return {
          film: null,
          loaded: false
      };
  },
  computed: {

  },
  methods: {
      onButtonfilmCloseClicked() {
          this.$router.back();
      }
  },
  mounted(){
      this.film = this.$store.getters.getFilmBySlug(this.slug);
      this.loaded = true;

  },
}
</script>
